export const EditIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M10 0C4.47712 0 0 4.47712 0 10C0 15.5229 4.47712 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47712 15.5229 0 10 0ZM7.78053 14.3779C7.69762 14.4608 7.60193 14.5276 7.49807 14.574L7.49733 14.5733L4.54636 15.8435C4.49599 15.8652 4.44695 15.8752 4.40134 15.8752C4.19762 15.8752 4.05973 15.6785 4.15646 15.4538L5.42675 12.5028C5.47236 12.3982 5.53923 12.3025 5.62229 12.2196L12.7446 5.09733L14.9028 7.25557L7.78053 14.3779ZM15.4559 6.70253L15.2484 6.90996L13.0902 4.75171L13.2976 4.54428C13.5801 4.26181 13.9404 4.11605 14.2765 4.11605C14.3765 4.11605 14.4744 4.12897 14.5675 4.15498C14.7207 4.19747 14.8611 4.27578 14.9762 4.39094L15.6094 5.02407C15.7245 5.13923 15.8027 5.27964 15.8453 5.43284C15.959 5.83863 15.8224 6.33626 15.456 6.70267L15.4559 6.70253Z"
      fill="#030303"
    />
  </svg>
);

export const ReviewChevronRight = () => (
  <svg
    width="28"
    height="45"
    viewBox="0 0 28 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 45L28 22.5L5.5 0L0.135414 5.36458L17.2708 22.5L0.135414 39.6354L5.5 45Z"
      fill="white"
    />
  </svg>
);
export const ReviewChevronLeft = () => (
  <svg
    width="28"
    height="45"
    viewBox="0 0 28 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 45L0 22.5L22.5 0L27.8646 5.36458L10.7292 22.5L27.8646 39.6354L22.5 45Z"
      fill="white"
      // fillOpacity="0.7"
    />
  </svg>
);
export const CheckIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.80486 11.5822L12.4664 5.92065L11.579 5.03321L6.80486 9.80731L4.40485 7.4073L3.51742 8.29474L6.80486 11.5822ZM8.00141 16C6.89494 16 5.85491 15.79 4.88132 15.3701C3.90772 14.9502 3.06082 14.3803 2.34064 13.6604C1.62044 12.9405 1.05028 12.094 0.63017 11.1208C0.210057 10.1477 0 9.10788 0 8.00141C0 6.89494 0.209966 5.85491 0.629896 4.88132C1.04983 3.90772 1.61972 3.06082 2.33958 2.34064C3.05946 1.62044 3.90598 1.05028 4.87915 0.630171C5.8523 0.210058 6.89212 0 7.99859 0C9.10506 0 10.1451 0.209966 11.1187 0.629897C12.0923 1.04983 12.9392 1.61972 13.6594 2.33959C14.3796 3.05946 14.9497 3.90598 15.3698 4.87915C15.7899 5.8523 16 6.89212 16 7.99859C16 9.10506 15.79 10.1451 15.3701 11.1187C14.9502 12.0923 14.3803 12.9392 13.6604 13.6594C12.9405 14.3796 12.094 14.9497 11.1208 15.3698C10.1477 15.7899 9.10788 16 8.00141 16ZM8 14.7369C9.88071 14.7369 11.4737 14.0842 12.779 12.779C14.0842 11.4737 14.7369 9.88071 14.7369 8C14.7369 6.11929 14.0842 4.52631 12.779 3.22104C11.4737 1.91577 9.88071 1.26314 8 1.26314C6.11929 1.26314 4.52631 1.91577 3.22104 3.22104C1.91577 4.52631 1.26314 6.11929 1.26314 8C1.26314 9.88071 1.91577 11.4737 3.22104 12.779C4.52631 14.0842 6.11929 14.7369 8 14.7369Z"
      fill="#1D8044"
    />
  </svg>
);

export const ThumbsUpIcon = ({
  fill,
  isHelpful,
}: {
  fill?: string;
  isHelpful?: boolean;
}) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill={`${fill && isHelpful ? fill : '#1C1B1F'}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4664 14H3.54706V4.96587L8.5419 0L9.1789 0.637002C9.25707 0.715185 9.32198 0.818702 9.37362 0.947552C9.42526 1.0764 9.45107 1.19777 9.45107 1.31167V1.50278L8.65191 4.96587H13.696C14.0541 4.96587 14.3704 5.10317 14.645 5.37777C14.9196 5.65236 15.0569 5.9687 15.0569 6.3268V7.54291C15.0569 7.62109 15.0468 7.70554 15.0265 7.79626C15.0062 7.88699 14.9835 7.97145 14.9585 8.04963L12.8013 13.14C12.6932 13.3813 12.5122 13.585 12.2583 13.751C12.0045 13.917 11.7405 14 11.4664 14ZM4.67631 12.8707H11.4664C11.5195 12.8707 11.5738 12.8563 11.6293 12.8273C11.6848 12.7983 11.727 12.7501 11.756 12.6825L13.9277 7.60082V6.3268C13.9277 6.25923 13.9059 6.20372 13.8625 6.16028C13.8191 6.11684 13.7635 6.09512 13.696 6.09512H7.22441L8.16837 1.96894L4.67631 5.44653V12.8707ZM3.54706 4.96587V6.09512H1.12925V12.8707H3.54706V14H0V4.96587H3.54706Z"
      // fill={`${fill && isHelpful ? fill : '#1C1B1F'}`}
    />
  </svg>
);

export const GrayCarIcon = () => (
  <svg
    width="58"
    height="59"
    viewBox="0 0 58 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="29" cy="29.9404" r="28.5" fill="#464646" stroke="#888888" />
    <path
      d="M36.3217 21.4534C36.2245 21.4304 36.1213 21.4072 36.0126 21.3842M36.0126 21.3842C33.7024 20.8941 28.8984 20.4453 25.876 22.1578L21.1167 24.8473C20.6016 25.2102 19.0646 25.9615 17.0373 26.064C15.1006 26.0426 11.0171 26.3714 10.1765 27.857C9.76447 28.5614 8.95271 30.5721 9.00216 32.9799V33.9404H14.0705M36.0126 21.3842C36.0126 21.3842 44.295 24.2479 48.4362 25.6798C48.4362 26.1707 48.4362 27.2935 48.4362 27.857C48.4362 28.4205 48.6422 28.9456 48.7452 29.1377L49.6724 29.9702C50.2657 30.7899 49.9196 31.7632 49.6724 32.1474L47.8799 33.9404H43.8936M21.1167 33.9404H36.7543"
      stroke="#CCCCCC"
      strokeWidth="1.2"
      strokeLinejoin="round"
    />
    <circle
      cx="2.5"
      cy="2.5"
      r="3.1"
      transform="matrix(-1 0 0 1 43 31.9404)"
      stroke="#CCCCCC"
      strokeWidth="1.2"
    />
    <circle
      cx="2.5"
      cy="2.5"
      r="3.1"
      transform="matrix(-1 0 0 1 20 31.9404)"
      stroke="#CCCCCC"
      strokeWidth="1.2"
    />
  </svg>
);

export const GraySunIcon = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="29.5" cy="29.9404" r="28.5" fill="#464646" stroke="#888888" />
    <path
      d="M28.3417 19.0872V13.9404H29.6583V19.0872H28.3417ZM36.7739 22.6041L35.8693 21.6994L39.462 17.972L40.4295 18.9485L36.7739 22.6041ZM39.3533 30.0987V28.7821H44.5V30.0987H39.3533ZM28.3417 44.9404V39.8236H29.6583V44.9404H28.3417ZM21.2799 22.5622L17.5375 18.9784L18.538 18.0409L22.1935 21.6665L21.2799 22.5622ZM39.4849 40.9028L35.8693 37.1814L36.765 36.3157L40.4055 39.8904L39.4849 40.9028ZM13.5 30.0987V28.7821H18.6467V30.0987H13.5ZM18.514 40.9028L17.6004 39.9024L21.1871 36.3157L21.6649 36.7737L22.1726 37.2443L18.514 40.9028ZM29.007 37.2203C26.8479 37.2203 25.0103 36.4647 23.4942 34.9533C21.9781 33.4419 21.2201 31.6066 21.2201 29.4475C21.2201 27.2883 21.9757 25.4507 23.4871 23.9346C24.9985 22.4186 26.8338 21.6605 28.9929 21.6605C31.1521 21.6605 32.9897 22.4162 34.5058 23.9276C36.0219 25.439 36.7799 27.2743 36.7799 29.4334C36.7799 31.5925 36.0242 33.4301 34.5128 34.9462C33.0014 36.4623 31.1662 37.2203 29.007 37.2203ZM28.9968 35.9037C30.7863 35.9037 32.3114 35.2744 33.5722 34.0158C34.8329 32.7572 35.4633 31.2331 35.4633 29.4436C35.4633 27.6541 34.834 26.129 33.5754 24.8682C32.3167 23.6075 30.7927 22.9771 29.0032 22.9771C27.2137 22.9771 25.6885 23.6064 24.4278 24.8651C23.167 26.1237 22.5367 27.6477 22.5367 29.4372C22.5367 31.2268 23.166 32.7519 24.4246 34.0126C25.6832 35.2734 27.2073 35.9037 28.9968 35.9037Z"
      fill="#CCCCCC"
    />
  </svg>
);

export const GrayWaterIcon = () => (
  <svg
    width="58"
    height="59"
    viewBox="0 0 58 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="29" cy="29.9404" r="28.5" fill="#464646" stroke="#888888" />
    <path
      d="M29 41.9404C26.5062 41.9404 24.3828 40.9739 22.6297 39.0408C20.8766 37.1077 20 34.7601 20 31.9982C20 30.6746 20.2378 29.4081 20.7133 28.199C21.1889 26.9898 21.8264 25.9108 22.6258 24.962L29 17.9404L35.3742 24.962C36.1736 25.9108 36.8111 26.9902 37.2867 28.2001C37.7622 29.41 38 30.6778 38 32.0035C38 34.7696 37.1234 37.1173 35.3703 39.0465C33.6172 40.9758 31.4937 41.9404 29 41.9404ZM28.993 40.7182C31.1889 40.7182 33.0577 39.8775 34.5994 38.1962C36.1412 36.5148 36.9121 34.4503 36.9121 32.0027C36.9121 30.8407 36.7128 29.7348 36.3141 28.6851C35.9154 27.6354 35.3527 26.7123 34.6258 25.9161L29 19.6812L23.3742 25.9161C22.6473 26.7119 22.0846 27.6343 21.6859 28.6834C21.2872 29.7325 21.0879 30.8371 21.0879 31.9973C21.0879 34.4482 21.8565 36.5145 23.3936 38.196C24.9307 39.8775 26.7972 40.7182 28.993 40.7182Z"
      fill="#CCCCCC"
    />
  </svg>
);

export const GarageIcon = () => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.917969 2.24805V16.8901H17.4643V2.24805H0.917969ZM16.1527 16.3852H2.22952V3.5119H16.1527V16.3852Z"
      fill="#1A1A1A"
    />
    <path d="M18.3848 0.106445H0V1.8734H18.3848V0.106445Z" fill="#1A1A1A" />
    <path d="M18.3848 16.0107H0V16.8942H18.3848V16.0107Z" fill="#1A1A1A" />
    <path
      d="M15.4973 4.39844H2.89062V5.02944H15.4973V4.39844Z"
      fill="#1A1A1A"
    />
    <path
      d="M15.4973 6.41602H2.89062V7.04702H15.4973V6.41602Z"
      fill="#1A1A1A"
    />
    <path d="M15.4973 8.43359H2.89062V9.0646H15.4973V8.43359Z" fill="#1A1A1A" />
    <path d="M15.4973 10.459H2.89062V11.09H15.4973V10.459Z" fill="#1A1A1A" />
    <path
      d="M15.4973 12.4766H2.89062V13.1076H15.4973V12.4766Z"
      fill="#1A1A1A"
    />
    <path d="M8.2749 14.4941H2.89062V15.1251H8.2749V14.4941Z" fill="#1A1A1A" />
    <path
      d="M15.4937 14.4941H10.1094V15.1251H15.4937V14.4941Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const MyGarage = () => (
  <div className="flex gap-[15px]">
    <GarageIcon />
    <p className="whitespace-nowrap text-[17px] font-[400] leading-[17px] text-[#767676]">
      My Garage
    </p>
  </div>
);
