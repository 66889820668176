export default function CarMagnify() {
  return (
    <svg
      id="Layer_2"
      className=""
      fill="white"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 129.53 57.68"
      width={67}
      height={30}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            fill="none"
            className="cls-1"
            d="M104.06,40.81c.39.56.59,1.22.59,1.89v.04l-.04,3.57c-.02,1.59-.74,2.79-2.01,3.85-.54.44-1.2.74-1.9.85l-4.7.81c.08-.49.12-1,.12-1.5,0-1.39-.29-2.74-.87-4.01-.56-1.23-1.35-2.33-2.36-3.28-1.01-.95-2.19-1.69-3.5-2.21-1.36-.54-2.8-.81-4.29-.81s-2.93.27-4.29.81c-1.31.52-2.49,1.26-3.5,2.21-1.01.95-1.81,2.05-2.36,3.28-.58,1.27-.87,2.62-.87,4.01,0,1.23.23,2.42.67,3.56H31.68c.45-1.14.67-2.33.67-3.56,0-1.39-.29-2.74-.87-4.01-.56-1.23-1.35-2.33-2.36-3.28-1.01-.95-2.19-1.69-3.5-2.21-1.36-.54-2.8-.81-4.29-.81s-2.93.27-4.29.81c-1.31.52-2.49,1.26-3.5,2.21-1.01.95-1.81,2.05-2.36,3.28-.58,1.27-.87,2.62-.87,4.01,0,.69.07,1.37.21,2.03l-3.88-.82c-.73-.15-1.5-.56-1.91-1.15-.26-.38-.42-.81-.58-1.23-.73-1.96-1.58-3.91-2.03-5.94-.57-2.53.28-4.71,2.6-6.14.42-.26.85-.5,1.29-.72,1.13-.57,2.33-1.03,3.55-1.39,5-1.48,10.27-1.83,15.47-2.14.62-.04,1.23-.07,1.85-.08.36-.01,1.29.14,1.59-.03l10.33-5.58c6.78-3.66,14.48-5.59,22.31-5.59H0v36.15h104.66v-18.42c-.35.08-.71.16-1.07.22.04.47.2.92.47,1.32ZM27.54,53.55c-1.21,2.18-3.71,3.68-6.6,3.68-3.45,0-6.34-2.13-7.15-5.02-.15-.52-.23-1.07-.23-1.64,0-3.68,3.3-6.66,7.37-6.66s7.37,2.98,7.37,6.66c0,1.07-.28,2.08-.77,2.98ZM91.86,52.75c-1,2.61-3.74,4.48-6.97,4.48-2.89,0-5.39-1.5-6.6-3.68-.5-.9-.78-1.91-.78-2.98,0-3.68,3.3-6.66,7.37-6.66s7.37,2.98,7.37,6.66c0,.76-.14,1.5-.41,2.18Z"
          />
          <path
            fill="none"
            className="cls-1"
            d="M76.04,23.88c-.29-.77-.53-1.55-.74-2.35h-14.2c3.33,0,6.65.34,9.88,1.01,1.71.35,3.4.81,5.06,1.34Z"
          />
          <path d="M28.46,32.7c-.3.16-1.23.01-1.59.03-.62.02-1.23.05-1.85.08-5.2.31-10.47.65-15.47,2.14-1.21.36-2.41.82-3.55,1.39-.44.22-.88.46-1.29.72-2.32,1.43-3.18,3.61-2.6,6.14.46,2.03,1.3,3.98,2.03,5.94.16.43.32.86.58,1.23.41.59,1.19,1,1.91,1.15l3.88.82c-.14-.66-.21-1.34-.21-2.03,0-1.39.29-2.74.87-4.01.56-1.23,1.35-2.33,2.36-3.28,1.01-.95,2.19-1.69,3.5-2.21,1.36-.54,2.8-.81,4.29-.81s2.93.27,4.29.81c1.31.52,2.49,1.26,3.5,2.21,1.01.95,1.81,2.05,2.36,3.28.58,1.27.87,2.62.87,4.01,0,1.23-.23,2.42-.67,3.56h43.07c-.45-1.14-.67-2.33-.67-3.56,0-1.39.29-2.74.87-4.01.56-1.23,1.35-2.33,2.36-3.28,1.01-.95,2.19-1.69,3.5-2.21,1.36-.54,2.8-.81,4.29-.81s2.93.27,4.29.81c1.31.52,2.49,1.26,3.5,2.21,1.01.95,1.81,2.05,2.36,3.28.58,1.27.87,2.62.87,4.01,0,.51-.04,1.01-.12,1.5l4.7-.81c.71-.11,1.37-.4,1.9-.85,1.27-1.06,2-2.26,2.01-3.85l.04-3.57v-.04c0-.68-.21-1.33-.59-1.89-.27-.4-.43-.85-.47-1.32-1.46.27-2.96.42-4.49.42-10.57,0-19.57-6.67-23.05-16.02-1.66-.54-3.35-.99-5.06-1.34-3.24-.67-6.55-1.01-9.88-1.01-7.83,0-15.52,1.93-22.31,5.59l-10.33,5.58Z" />
          <path d="M20.94,43.91c-4.07,0-7.37,2.98-7.37,6.66,0,.57.08,1.12.23,1.64.81,2.89,3.7,5.02,7.15,5.02,2.89,0,5.39-1.5,6.6-3.68.5-.9.77-1.91.77-2.98,0-3.68-3.3-6.66-7.37-6.66Z" />
          <path d="M84.89,43.91c-4.07,0-7.37,2.98-7.37,6.66,0,1.07.28,2.08.78,2.98,1.21,2.18,3.71,3.68,6.6,3.68,3.22,0,5.97-1.87,6.97-4.48.26-.68.41-1.42.41-2.18,0-3.68-3.3-6.66-7.37-6.66Z" />
        </g>
        <path d="M129.53,41.24l-16.2-15.08c1.4-2.53,2.21-5.44,2.21-8.53,0-9.72-7.91-17.63-17.63-17.63s-17.63,7.91-17.63,17.63,7.91,17.63,17.63,17.63c4.35,0,8.32-1.59,11.4-4.21l15.91,14.81,4.31-4.63ZM86.59,17.63c0-6.23,5.07-11.31,11.31-11.31s11.31,5.07,11.31,11.31-5.07,11.31-11.31,11.31-11.31-5.07-11.31-11.31Z" />
      </g>
    </svg>
  );
}
