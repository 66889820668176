'use client';
import { useItemViewedGoogleTag } from '@/hooks/useGoogleTagDataLayer';
import useStoreContext from '@/hooks/useStoreContext';
import { useStore } from 'zustand';

export default function ViewItemGoogleTag() {
  const store = useStoreContext();
  if (!store) throw new Error('Missing Provider in the tree');
  const selectedProduct = useStore(store, (s) => s.selectedProduct);
  useItemViewedGoogleTag(selectedProduct);

  return <></>;
}
