import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const WindBlowingGerms: React.FC<IconProps> = ({
  width = 50,
  height = 50,
  color = 'none',
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      cx="25"
      cy="25"
      r="24.625"
      fill="#EAEAEA"
      fillOpacity="0.7"
      stroke="#888888"
      strokeWidth="0.75"
    />
    <g clipPath="url(#clip0_5998_49697)">
      <path
        d="M11.4375 22.1875H28.7812C30.5906 22.1875 32.0625 20.7152 32.0625 18.9062C32.0625 17.0973 30.5906 15.625 28.7812 15.625C26.9719 15.625 25.5 17.0973 25.5 18.9062C25.5 19.2559 25.5548 19.6 25.6631 19.9305C25.7437 20.1761 26.0072 20.3111 26.2547 20.23C26.5008 20.1494 26.6348 19.8845 26.5542 19.6384C26.4764 19.4031 26.4375 19.1566 26.4375 18.9062C26.4375 17.6139 27.4889 16.5625 28.7812 16.5625C30.0736 16.5625 31.125 17.6139 31.125 18.9062C31.125 20.1986 30.0736 21.25 28.7812 21.25H11.4375C11.1788 21.25 10.9688 21.4595 10.9688 21.7188C10.9688 21.978 11.1788 22.1875 11.4375 22.1875Z"
        fill="#313030"
      />
      <path
        d="M36.2812 18.9062C34.2136 18.9062 32.5312 20.5886 32.5312 22.6562C32.5312 23.162 32.6302 23.6533 32.8256 24.1159C32.9264 24.3541 33.2025 24.4666 33.4402 24.3648C33.6787 24.2641 33.7903 23.9889 33.6895 23.7503C33.5433 23.4044 33.4688 23.0359 33.4688 22.6562C33.4688 21.1052 34.7302 19.8438 36.2812 19.8438C37.8323 19.8438 39.0938 21.1052 39.0938 22.6562C39.0938 24.2073 37.8323 25.4688 36.2812 25.4688H11.4375C11.1788 25.4688 10.9688 25.6783 10.9688 25.9375C10.9688 26.1967 11.1788 26.4062 11.4375 26.4062H36.2812C38.3489 26.4062 40.0312 24.7239 40.0312 22.6562C40.0312 20.5886 38.3489 18.9062 36.2812 18.9062Z"
        fill="#313030"
      />
      <path
        d="M28.3125 32.0312C28.3125 30.4802 27.0511 29.2188 25.5 29.2188H11.4375C11.1788 29.2188 10.9688 29.4283 10.9688 29.6875C10.9688 29.9467 11.1788 30.1562 11.4375 30.1562H25.5C26.5341 30.1562 27.375 30.9972 27.375 32.0312C27.375 33.0653 26.5341 33.9062 25.5 33.9062C24.4659 33.9062 23.625 33.0653 23.625 32.0312C23.625 31.8677 23.6461 31.705 23.6878 31.5475C23.7544 31.2972 23.6053 31.0403 23.355 30.9737C23.1056 30.9091 22.8483 31.0567 22.7817 31.3066C22.7194 31.5428 22.6875 31.787 22.6875 32.0312C22.6875 33.5823 23.9489 34.8438 25.5 34.8438C27.0511 34.8438 28.3125 33.5823 28.3125 32.0312Z"
        fill="#313030"
      />
      <path
        d="M34.6406 17.5C35.5453 17.5 36.2812 16.7641 36.2812 15.8594C36.2812 14.9547 35.5453 14.2188 34.6406 14.2188C33.7359 14.2188 33 14.9547 33 15.8594C33 16.7641 33.7359 17.5 34.6406 17.5ZM34.6406 15.1562C35.0283 15.1562 35.3438 15.4717 35.3438 15.8594C35.3438 16.247 35.0283 16.5625 34.6406 16.5625C34.253 16.5625 33.9375 16.247 33.9375 15.8594C33.9375 15.4717 34.253 15.1562 34.6406 15.1562Z"
        fill="#313030"
      />
      <path
        d="M32.5312 28.9844C32.5312 28.0797 31.7953 27.3438 30.8906 27.3438C29.9859 27.3438 29.25 28.0797 29.25 28.9844C29.25 29.8891 29.9859 30.625 30.8906 30.625C31.7953 30.625 32.5312 29.8891 32.5312 28.9844ZM30.8906 29.6875C30.503 29.6875 30.1875 29.372 30.1875 28.9844C30.1875 28.5967 30.503 28.2812 30.8906 28.2812C31.2783 28.2812 31.5938 28.5967 31.5938 28.9844C31.5938 29.372 31.2783 29.6875 30.8906 29.6875Z"
        fill="#313030"
      />
      <path
        d="M37.4531 29.6875C37.8415 29.6875 38.1562 29.3727 38.1562 28.9844C38.1562 28.596 37.8415 28.2812 37.4531 28.2812C37.0648 28.2812 36.75 28.596 36.75 28.9844C36.75 29.3727 37.0648 29.6875 37.4531 29.6875Z"
        fill="#313030"
      />
      <path
        d="M28.0781 37.6562C28.4665 37.6562 28.7812 37.3415 28.7812 36.9531C28.7812 36.5648 28.4665 36.25 28.0781 36.25C27.6898 36.25 27.375 36.5648 27.375 36.9531C27.375 37.3415 27.6898 37.6562 28.0781 37.6562Z"
        fill="#313030"
      />
      <path
        d="M29.0156 14.2188C29.404 14.2188 29.7188 13.904 29.7188 13.5156C29.7188 13.1273 29.404 12.8125 29.0156 12.8125C28.6273 12.8125 28.3125 13.1273 28.3125 13.5156C28.3125 13.904 28.6273 14.2188 29.0156 14.2188Z"
        fill="#313030"
      />
      <path
        d="M14.9531 14.6875C15.3415 14.6875 15.6562 14.3727 15.6562 13.9844C15.6562 13.596 15.3415 13.2812 14.9531 13.2812C14.5648 13.2812 14.25 13.596 14.25 13.9844C14.25 14.3727 14.5648 14.6875 14.9531 14.6875Z"
        fill="#313030"
      />
      <path
        d="M13.0781 19.375C13.4665 19.375 13.7812 19.0602 13.7812 18.6719C13.7812 18.2835 13.4665 17.9688 13.0781 17.9688C12.6898 17.9688 12.375 18.2835 12.375 18.6719C12.375 19.0602 12.6898 19.375 13.0781 19.375Z"
        fill="#313030"
      />
      <path
        d="M18.7031 20.3125C19.0915 20.3125 19.4062 19.9977 19.4062 19.6094C19.4062 19.221 19.0915 18.9062 18.7031 18.9062C18.3148 18.9062 18 19.221 18 19.6094C18 19.9977 18.3148 20.3125 18.7031 20.3125Z"
        fill="#313030"
      />
      <path
        d="M37.6535 32.0312C37.5241 31.8072 37.2377 31.7294 37.0132 31.8597L36.5721 32.1142C36.1507 31.6056 35.553 31.2508 34.8747 31.1359V30.625C34.8747 30.3658 34.6647 30.1562 34.406 30.1562C34.1472 30.1562 33.9372 30.3658 33.9372 30.625V31.1359C33.2585 31.2503 32.6608 31.6061 32.2399 32.1147L31.7993 31.8597C31.5752 31.7289 31.2883 31.8067 31.1585 32.0303C31.0291 32.2548 31.1055 32.5412 31.3296 32.6706L31.773 32.927C31.6586 33.2331 31.5935 33.5617 31.5935 33.9062C31.5935 34.2508 31.6586 34.5798 31.7725 34.885L31.3291 35.1409C31.105 35.2703 31.0282 35.5567 31.1575 35.7812C31.2447 35.9312 31.4018 36.0156 31.564 36.0156C31.6432 36.0156 31.7238 35.9955 31.7979 35.9528L32.2394 35.6978C32.6608 36.2064 33.258 36.5617 33.9368 36.6761V37.1875C33.9368 37.4467 34.1468 37.6562 34.4055 37.6562C34.6643 37.6562 34.8743 37.4467 34.8743 37.1875V36.6766C35.5525 36.5622 36.1502 36.2069 36.5716 35.6983L37.0122 35.9533C37.0863 35.9964 37.1669 36.0166 37.2466 36.0166C37.4083 36.0166 37.5658 35.9327 37.653 35.7827C37.7824 35.5581 37.706 35.2717 37.4819 35.1423L37.039 34.8859C37.1529 34.5803 37.2185 34.2513 37.2185 33.9062C37.2185 33.5617 37.1533 33.2327 37.039 32.927L37.4815 32.6716C37.706 32.5422 37.7829 32.2558 37.6535 32.0312ZM34.406 35.7812C33.3719 35.7812 32.531 34.9403 32.531 33.9062C32.531 32.8722 33.3719 32.0312 34.406 32.0312C35.44 32.0312 36.281 32.8722 36.281 33.9062C36.281 34.9403 35.44 35.7812 34.406 35.7812Z"
        fill="#313030"
      />
      <path
        d="M34.4062 33.4375C34.6651 33.4375 34.875 33.2276 34.875 32.9688C34.875 32.7099 34.6651 32.5 34.4062 32.5C34.1474 32.5 33.9375 32.7099 33.9375 32.9688C33.9375 33.2276 34.1474 33.4375 34.4062 33.4375Z"
        fill="#313030"
      />
      <path
        d="M33.3602 33.9693C33.1362 34.0982 33.0593 34.3851 33.1891 34.6091C33.3185 34.8332 33.6054 34.9105 33.829 34.7812C34.0535 34.6513 34.1304 34.3645 34.0005 34.1404C33.8712 33.9159 33.5843 33.8399 33.3602 33.9693Z"
        fill="#313030"
      />
      <path
        d="M35.4521 33.9694C35.2281 33.8396 34.9412 33.9164 34.8118 34.1405C34.6825 34.3646 34.7593 34.6514 34.9839 34.7803C35.2075 34.9102 35.4939 34.8333 35.6237 34.6092C35.7531 34.3847 35.6762 34.0983 35.4521 33.9694Z"
        fill="#313030"
      />
      <path
        d="M20.7785 33.9062C20.6491 33.6822 20.3627 33.6048 20.1382 33.7347L19.6971 33.9892C19.2757 33.4806 18.678 33.1258 17.9997 33.0109V32.5C17.9997 32.2408 17.7897 32.0312 17.531 32.0312C17.2722 32.0312 17.0622 32.2408 17.0622 32.5V33.0109C16.3835 33.1253 15.7858 33.4811 15.3649 33.9897L14.9243 33.7347C14.7002 33.6048 14.4133 33.6817 14.2835 33.9053C14.1541 34.1298 14.2305 34.4162 14.4546 34.5456L14.898 34.802C14.7836 35.1081 14.7185 35.4367 14.7185 35.7812C14.7185 36.1258 14.7836 36.4548 14.8975 36.76L14.4541 37.0159C14.23 37.1453 14.1532 37.4317 14.2825 37.6562C14.3697 37.8062 14.5268 37.8906 14.689 37.8906C14.7682 37.8906 14.8488 37.8705 14.9229 37.8278L15.3644 37.5728C15.7858 38.0814 16.383 38.4367 17.0618 38.5511V39.0625C17.0618 39.3217 17.2718 39.5312 17.5305 39.5312C17.7893 39.5312 17.9993 39.3217 17.9993 39.0625V38.5516C18.6775 38.4372 19.2752 38.0819 19.6966 37.5733L20.1372 37.8283C20.2113 37.8714 20.2919 37.8916 20.3716 37.8916C20.5333 37.8916 20.6908 37.8077 20.778 37.6577C20.9074 37.4331 20.831 37.1467 20.6069 37.0173L20.164 36.7609C20.2779 36.4553 20.3435 36.1263 20.3435 35.7812C20.3435 35.4367 20.2783 35.1077 20.164 34.802L20.6065 34.5466C20.831 34.4172 20.9079 34.1308 20.7785 33.9062ZM17.531 37.6562C16.4969 37.6562 15.656 36.8153 15.656 35.7812C15.656 34.7472 16.4969 33.9062 17.531 33.9062C18.565 33.9062 19.406 34.7472 19.406 35.7812C19.406 36.8153 18.565 37.6562 17.531 37.6562Z"
        fill="#313030"
      />
      <path
        d="M17.5312 35.3125C17.7901 35.3125 18 35.1026 18 34.8438C18 34.5849 17.7901 34.375 17.5312 34.375C17.2724 34.375 17.0625 34.5849 17.0625 34.8438C17.0625 35.1026 17.2724 35.3125 17.5312 35.3125Z"
        fill="#313030"
      />
      <path
        d="M16.4852 35.8443C16.2612 35.9732 16.1843 36.2601 16.3141 36.4841C16.4435 36.7082 16.7304 36.7855 16.954 36.6562C17.1785 36.5263 17.2554 36.2395 17.1255 36.0154C16.9962 35.7909 16.7093 35.7149 16.4852 35.8443Z"
        fill="#313030"
      />
      <path
        d="M18.5771 35.8444C18.3531 35.7146 18.0662 35.7914 17.9368 36.0155C17.8075 36.2396 17.8843 36.5264 18.1089 36.6553C18.3325 36.7852 18.6189 36.7083 18.7487 36.4842C18.8781 36.2597 18.8012 35.9733 18.5771 35.8444Z"
        fill="#313030"
      />
      <path
        d="M18.0325 16.0938C18.1624 16.3178 18.4488 16.3947 18.6729 16.2653L19.1144 16.0103C19.5358 16.5189 20.133 16.8742 20.8118 16.9886V17.5C20.8118 17.7592 21.0218 17.9688 21.2805 17.9688C21.5393 17.9688 21.7493 17.7592 21.7493 17.5V16.9891C22.4275 16.8747 23.0252 16.5194 23.4466 16.0108L23.8872 16.2658C23.9613 16.3089 24.0419 16.3291 24.1216 16.3291C24.2833 16.3291 24.4408 16.2452 24.528 16.0952C24.6574 15.8706 24.581 15.5842 24.3569 15.4548L23.914 15.1984C24.0279 14.8928 24.0935 14.5638 24.0935 14.2188C24.0935 13.8742 24.0283 13.5452 23.914 13.2395L24.3565 12.9841C24.581 12.8547 24.6579 12.5683 24.5285 12.3438C24.3986 12.1197 24.1127 12.0423 23.8882 12.1722L23.4466 12.4267C23.0252 11.9181 22.428 11.5633 21.7497 11.4484V10.9375C21.7497 10.6783 21.5397 10.4688 21.281 10.4688C21.0222 10.4688 20.8122 10.6783 20.8122 10.9375V11.4484C20.1335 11.5628 19.5358 11.9186 19.1149 12.4272L18.6743 12.1722C18.4497 12.0419 18.1633 12.1188 18.0335 12.3428C17.9041 12.5673 17.9805 12.8537 18.2046 12.9831L18.648 13.2395C18.5336 13.5456 18.4685 13.8742 18.4685 14.2188C18.4685 14.5633 18.5336 14.8923 18.6475 15.1975L18.2041 15.4534C17.98 15.5828 17.9032 15.8692 18.0325 16.0938ZM21.281 12.3438C22.315 12.3438 23.156 13.1847 23.156 14.2188C23.156 15.2528 22.315 16.0938 21.281 16.0938C20.2469 16.0938 19.406 15.2528 19.406 14.2188C19.406 13.1847 20.2469 12.3438 21.281 12.3438Z"
        fill="#313030"
      />
      <path
        d="M21.2812 13.75C21.5401 13.75 21.75 13.5401 21.75 13.2812C21.75 13.0224 21.5401 12.8125 21.2812 12.8125C21.0224 12.8125 20.8125 13.0224 20.8125 13.2812C20.8125 13.5401 21.0224 13.75 21.2812 13.75Z"
        fill="#313030"
      />
      <path
        d="M20.704 15.0937C20.9285 14.9638 21.0054 14.677 20.8755 14.4529C20.7462 14.2284 20.4593 14.1524 20.2352 14.2818C20.0112 14.4107 19.9343 14.6976 20.0641 14.9216C20.193 15.1457 20.4799 15.223 20.704 15.0937Z"
        fill="#313030"
      />
      <path
        d="M21.7348 14.8741C21.9584 15.0039 22.2448 14.9271 22.3747 14.703C22.5041 14.4785 22.4276 14.1921 22.2031 14.0631C21.9791 13.9333 21.6922 14.0102 21.5628 14.2342C21.4334 14.4583 21.5103 14.7452 21.7348 14.8741Z"
        fill="#313030"
      />
    </g>
    <defs>
      <clipPath id="clip0_5998_49697">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(10.5 10)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WindBlowingGerms;
