
export const GrayWrench = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-[100px] w-[100px] max-lg:h-[58px] max-lg:w-[58px] "
    >
      <circle
        cx="50"
        cy="50"
        r="49"
        fill="#464646"
        stroke="#888888"
        strokeWidth="2"
      />
      <path
        d="M67.7918 74.6932L48.0071 54.9084C46.8567 55.4431 45.6894 55.8766 44.5054 56.2088C43.3214 56.541 42.051 56.707 40.6942 56.707C36.3926 56.707 32.7362 55.1994 29.7252 52.1842C26.7141 49.1689 25.2085 45.5075 25.2085 41.2001C25.2085 40.1469 25.3093 39.1145 25.511 38.1029C25.7127 37.0914 26.017 36.1146 26.4238 35.1723L36.6321 45.1862L44.7988 37.1167L34.8335 27.0057C35.7757 26.5988 36.7306 26.2703 37.6981 26.02C38.6656 25.7697 39.6713 25.6445 40.7155 25.6445C45.0499 25.6445 48.7342 27.1565 51.7684 30.1803C54.8026 33.2042 56.3196 36.876 56.3196 41.1958C56.3196 42.6408 56.1657 43.9547 55.8578 45.1376C55.5499 46.3205 55.1043 47.4385 54.521 48.4917L74.3057 68.2765C74.6622 68.6485 74.8405 69.1223 74.8405 69.6979C74.8405 70.2734 74.6676 70.734 74.322 71.0797L70.4493 74.8552C70.1134 75.2009 69.6757 75.3494 69.1361 75.3008C68.5964 75.2522 68.1483 75.0496 67.7918 74.6932ZM69.2015 73.6723L73.1877 69.6862L52.3092 48.8077C53.0384 47.7059 53.5974 46.5028 53.9863 45.1984C54.3752 43.8939 54.5696 42.5612 54.5696 41.2001C54.5696 37.3274 52.9736 33.8517 49.7814 30.773C46.5893 27.6943 42.5464 26.6005 37.6529 27.4917L45.8925 35.7313C46.2976 36.1335 46.4921 36.6108 46.4759 37.1632C46.4596 37.7155 46.249 38.1943 45.8439 38.5994L38.0661 46.2799C37.6552 46.685 37.1757 46.8876 36.6277 46.8876C36.0797 46.8876 35.6032 46.685 35.1981 46.2799L27.0557 38.1376C26.3103 43.6306 27.5028 47.8071 30.6331 50.6671C33.7633 53.5271 37.1241 54.957 40.7155 54.957C42.0117 54.957 43.3161 54.7747 44.6286 54.4102C45.9411 54.0456 47.1564 53.4744 48.2744 52.6966L69.2015 73.6723Z"
        fill="#CCCCCC"
        fillOpacity="0.8"
      />
    </svg>
  );
};
