import { Button } from '@/components/ui/button';
import { useState } from 'react';
import { getCompleteSelectionData } from '../../utils';
import CarMagnify from '@/components/icons/CarMagnify';
import useStoreContext from '@/hooks/useStoreContext';
import { useStore } from 'zustand';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

type AddToCartButtonProps = {
  handleAddToCartClicked: () => void;
  preorder: boolean;
  isColorAvailable: boolean;
  isLoading: boolean;
};

type ButtonText =
  | 'Start Here'
  | 'Find your Custom-Cover'
  | 'Pre-order & Save Big'
  | 'Add To Cart';

const BUTTON_STATES: Record<string, ButtonText> = {
  START: 'Start Here',
  FIND_COVER: 'Find your Custom-Cover',
  PREORDER: 'Pre-order & Save Big',
  ADD_TO_CART: 'Add To Cart',
};

const ANIMATION_CONFIG = {
  blinkTime: 2,
  blinkVelocity: 10,
};

export default function AddToCartButton({
  preorder,
  isColorAvailable,
  handleAddToCartClicked,
  isLoading,
}: AddToCartButtonProps) {
  const [buttonText, setButtonText] = useState<ButtonText>(
    BUTTON_STATES.FIND_COVER
  );

  // Store handling
  const store = useStoreContext();
  if (!store) {
    throw new Error('Store Provider missing in component tree');
  }
  const modelData = useStore(store, (s) => s.modelData);

  // Selection completion check
  const {
    completeSelectionState: { isComplete },
  } = getCompleteSelectionData({
    data: modelData,
  });

  // Animation styles
  const getAnimationStyle = () => {
    // Always apply animation except when in loading state
    if (!isLoading) {
      return {
        animation: `blink ${ANIMATION_CONFIG.blinkTime}s cubic-bezier(0,-${ANIMATION_CONFIG.blinkVelocity},1,${ANIMATION_CONFIG.blinkVelocity}) infinite`,
      };
    }
    return {};
  };

  // Button text rotation logic
  const handleAnimationIteration = () => {
    setButtonText((currentText) => {
      switch (currentText) {
        case BUTTON_STATES.PREORDER:
          return BUTTON_STATES.PREORDER;
        case BUTTON_STATES.START:
          return BUTTON_STATES.FIND_COVER;
        default:
          return BUTTON_STATES.START;
      }
    });
  };

  // Determine final button text
  const getFinalButtonText = (): ButtonText => {
    if (!isComplete) return buttonText;
    return preorder ? BUTTON_STATES.PREORDER : BUTTON_STATES.ADD_TO_CART;
  };

  // Render car magnify icon when appropriate
  const shouldShowCarMagnify = () => {
    return (
      !isLoading &&
      buttonText === BUTTON_STATES.START &&
      !preorder &&
      !isComplete
    );
  };

  return (
    <Button
      className="h-[48px] w-full rounded bg-[#BE1B1B] text-lg font-bold uppercase 
                 text-white disabled:bg-[#BE1B1B] lg:h-[62px]"
      onClick={handleAddToCartClicked}
      disabled={!isColorAvailable}
    >
      <div
        className="flex gap-[10px]"
        style={getAnimationStyle()}
        onAnimationIteration={handleAnimationIteration}
      >
        {isLoading && <AiOutlineLoading3Quarters className="animate-spin" />}

        {shouldShowCarMagnify() && (
          <div className="flex min-h-[30px] min-w-[67px]">
            <CarMagnify />
          </div>
        )}

        {!isLoading && <p>{getFinalButtonText()}</p>}
      </div>
    </Button>
  );
}
