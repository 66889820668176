import { ArrowLeft } from 'lucide-react';
import {
  FLOORMAT_PATTERN_ACK,
  PREORDER_CONFIRM,
  PreorderStep,
} from './PreorderSheet';

type PreorderHeaderProps = {
  currentStep: PreorderStep;
  handlePreviousStep: () => void;
};
const PreorderHeader = ({
  currentStep,
  handlePreviousStep,
}: PreorderHeaderProps): JSX.Element => {
  return (
    <div>
      <div className="flex flex-row ">
        {(currentStep === PREORDER_CONFIRM ||
          currentStep === FLOORMAT_PATTERN_ACK) && (
          <ArrowLeft onClick={handlePreviousStep} />
        )}
      </div>
    </div>
  );
};

export default PreorderHeader;
