import { useStore } from 'zustand';
import useStoreContext from './useStoreContext';
import { getCompleteSelectionData } from '@/utils';

type FloorMatImageData = {
  type: string;
  description: string;
  buckleUp: string;
  weatherProtection: string;
};

// Define the mapping type
type FloorMatImageMap = {
  default: FloorMatImageData;
  main: FloorMatImageData;
  offroad: FloorMatImageData;
  regular: FloorMatImageData;
  luxury: FloorMatImageData;
  sports: FloorMatImageData;
  classic: FloorMatImageData;
  unknown: FloorMatImageData;
};

const BASE_URL = 'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat';

const FLOOR_MAT_IMAGE_MAP: FloorMatImageMap = {
  default: {
    type: 'Default',
    description: `${BASE_URL}/description/fm-tx-dsc-rg-01.webp`,
    buckleUp: `${BASE_URL}/description/fm-tx-dsc-rg-02.webp`,
    weatherProtection: `${BASE_URL}/description/fm-tx-dsc-rg-07.webp`,
  },
  main: {
    type: 'Main',
    description: `${BASE_URL}/description/floormat_pdp_1.webp`,
    buckleUp: `${BASE_URL}/description/floormat_pdp_2.webp`,
    weatherProtection: `${BASE_URL}/description/fllormat_pdp_7.webp`,
  },
  offroad: {
    type: 'Offroad',
    description: `${BASE_URL}/description/fm-tx-dsc-or-01.webp`,
    buckleUp: `${BASE_URL}/description/fm-tx-dsc-or-02.webp`,
    weatherProtection: `${BASE_URL}/description/fm-tx-dsc-or-07.webp`,
  },
  regular: {
    type: 'Regular',
    description: `${BASE_URL}/description/fm-tx-dsc-rg-01.webp`,
    buckleUp: `${BASE_URL}/description/fm-tx-dsc-rg-02.webp`,
    weatherProtection: `${BASE_URL}/description/fm-tx-dsc-rg-07.webp`,
  },
  luxury: {
    type: 'Luxury',
    description: `${BASE_URL}/description/fm-tx-dsc-lx-01.webp`,
    buckleUp: `${BASE_URL}/description/fm-tx-dsc-lx-02.webp`,
    weatherProtection: `${BASE_URL}/description/fm-tx-dsc-lx-07.webp`,
  },
  sports: {
    type: 'Sports',
    description: `${BASE_URL}/description/fm-tx-dsc-sp-01.webp`,
    buckleUp: `${BASE_URL}/description/fm-tx-dsc-sp-02.webp`,
    weatherProtection: `${BASE_URL}/description/fm-tx-dsc-sp-07.webp`,
  },
  classic: {
    type: 'Classic',
    description: `${BASE_URL}/description/fm-tx-dsc-cl-01.webp`,
    buckleUp: `${BASE_URL}/description/fm-tx-dsc-cl-02.webp`,
    weatherProtection: `${BASE_URL}/description/fm-tx-dsc-cl-07.webp`,
  },
  unknown: {
    type: 'Unknown',
    description: `${BASE_URL}/description/floormat_pdp_1.webp`,
    buckleUp: `${BASE_URL}/description/floormat_pdp_2.webp`,
    weatherProtection: `${BASE_URL}/description/fllormat_pdp_7.webp`,
  },
};

export function useDetermineFloorMatType() {
  const store = useStoreContext();
  if (!store) throw new Error('Missing Provider in the tree');

  const selectedProduct = useStore(store, (s) => s.selectedProduct);
  const modelData = useStore(store, (s) => s.modelData);

  const mainImage = selectedProduct?.feature || '';

  const {
    completeSelectionState: { isComplete },
  } = getCompleteSelectionData({
    data: modelData,
  });

  const getTypeFromUrl = (url: string) => {
    if (!isComplete) return 'default';
    if (url.includes('main')) return 'main';
    if (url.includes('-tx-or-')) return 'offroad';
    if (url.includes('-tx-rg-')) return 'regular';
    if (url.includes('-tx-lx-')) return 'luxury';
    if (url.includes('-tx-sp-')) return 'sports';
    if (url.includes('-tx-cl-')) return 'classic';
    return 'unknown';
  };

  const type = getTypeFromUrl(mainImage) as keyof FloorMatImageMap;
  const imageData: FloorMatImageData = FLOOR_MAT_IMAGE_MAP[type];

  return {
    type: imageData.type || 'Unknown',
    images: {
      description: imageData.description || '',
      buckleUp: imageData.buckleUp || '',
      weatherProtection: imageData.weatherProtection || '',
    },
    isOffroad: type === 'offroad',
    isLuxury: type === 'luxury',
    isRegular: type === 'regular',
    isSports: type === 'sports',
    isClassic: type === 'classic',
  };
}
