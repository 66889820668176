import { useCartContext } from '@/providers/CartProvider';
import { Button } from '../ui/button';
import { useParams, useRouter } from 'next/navigation';
import {
  FLOORMAT_PATTERN_ACK,
  PREORDER_CONFIRM,
  PREORDER_PREVIEW,
  PreorderStep,
} from './PreorderSheet';
import { handlePreorderAddToCartGoogleTag } from '@/hooks/useGoogleTagDataLayer';
import useStoreContext from '@/hooks/useStoreContext';
import { TPathParams } from '@/utils';
import { useStore } from 'zustand';

type PreorderFooterProps = {
  currentStep: PreorderStep;
  handleNextStep: () => void;
  preorderChecked: boolean;
  // floorMatAckChecked: boolean;
};
const PreorderFooter = ({
  currentStep,
  handleNextStep,
  preorderChecked,
  // floorMatAckChecked,
}: PreorderFooterProps) => {
  const params = useParams<TPathParams>();
  const store = useStoreContext();
  if (!store) throw new Error('Missing Provider in the tree');
  const selectedProduct = useStore(store, (s) => s.selectedProduct);

  const router = useRouter();
  const { setCartOpen, addToCart } = useCartContext();

  const handleClick = () => {
    // if (currentStep === FLOORMAT_PATTERN_ACK) {
    //   handlePreorderAddToCartGoogleTag(
    //     selectedProduct,
    //     params as TPathParams,
    //     'acknowledge-checkbox_floor-mat-pattern'
    //   );
    //   handleNextStep();
    // }
    if (currentStep === PREORDER_PREVIEW) {
      handlePreorderAddToCartGoogleTag(
        selectedProduct,
        params as TPathParams,
        'preorder_2_discount-and-date'
      );
      handleNextStep();
    } else if (currentStep === PREORDER_CONFIRM) {
      handlePreorderAddToCartGoogleTag(
        selectedProduct,
        params as TPathParams,
        'preorder_3_acknowledge-checkbox'
      );
      setCartOpen(false);
      // TODO: Floor Mat when multiple rows come out
      // Need to add selectedProducts.forEach here
      addToCart({ ...selectedProduct, quantity: 1 });

      router.push('/checkout');
    }
  };

  const isDisabled =
    currentStep === PREORDER_CONFIRM && preorderChecked === false;
  // || (currentStep === FLOORMAT_PATTERN_ACK && floorMatAckChecked === false);

  return (
    <div className="p-4 lg:p-5">
      <Button
        onClick={handleClick}
        aria-label="Close"
        disabled={isDisabled}
        className="my-3 h-[48px] w-full bg-[#BE1B1B] text-base font-bold uppercase text-white disabled:bg-[#BE1B1B] md:h-[62px] md:text-lg"
      >
        {currentStep === PREORDER_CONFIRM
          ? // || currentStep === FLOORMAT_PATTERN_ACK
            'Checkout'
          : 'Pre-Order Now'}
      </Button>
    </div>
  );
};

export default PreorderFooter;
