import Image from 'next/image';
import { useDetermineFloorMatType } from '@/hooks/useDetermineFloorMatType';

export default function BuckleUpStaySecure() {
  const { images, isClassic } = useDetermineFloorMatType();

  const headerText = isClassic
    ? 'No Slip, Stay secured'
    : 'Buckle Up, Stay secured';
  const subheader1Text = isClassic
    ? 'Stays Locked in Place'
    : 'No More Sliding - Snap-in Buckles';
  const subheader2Text = isClassic
    ? 'No Hassle, No Movement'
    : 'Keep Mats Securely Anchored';

  return (
    <section className="relative w-full bg-[#1A1A1A]">
      <h2 className="flex w-full items-center justify-center pt-[60px] text-center text-[26px] font-[500] leading-[30px] text-white lg:pt-[110px] lg:text-[45px] lg:leading-[52px] ">
        {headerText}
      </h2>
      <div className="pt-[10px]">
        <p className="text-center text-sm font-light text-[#D3D3D3] lg:text-[28px] lg:font-normal lg:leading-9">
          {subheader1Text} <br />
          {subheader2Text}
        </p>
      </div>
      <section className="relative flex w-full flex-row justify-center pt-[34px] lg:pt-12">
        <div className="relative flex justify-center">
          <Image
            alt={`perfect-custom-fit`}
            width={889}
            height={1022}
            src={images.buckleUp}
            className="z-[2] min-w-full lg:max-h-[1022px] lg:max-w-[889px]"
          />
          {/* Gradient div */}
        </div>
        <div className="absolute bottom-0 z-[1] h-[200px] w-full bg-gradient-to-t from-[#FFFFFF] to-[#1A1A1A] lg:h-[500px]"></div>
      </section>
    </section>
  );
}
