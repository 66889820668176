import useStoreContext from '@/hooks/useStoreContext';
import { useStore } from 'zustand';

export default function PreorderItemBreakdown() {
  const store = useStoreContext();
  if (!store) throw new Error('Missing Provider in the tree');
  const selectedProduct = useStore(store, (s) => s.selectedProduct);
  const discountPrice = selectedProduct.price - selectedProduct.msrp;
  const itemTotal = selectedProduct.msrp - selectedProduct.preorder_discount;
  return (
    <div className=" text-base font-normal text-[#343434]">
      <div className="flex justify-between pt-2 lg:flex">
        <div>Item Subtotal</div>
        <div>${selectedProduct.price.toFixed(2)}</div>
      </div>
      <div className="flex justify-between text-[#D13C3F]">
        <div>Sale-discount</div>
        <div>- ${discountPrice}</div>
      </div>
      <div className="flex justify-between text-[#D13C3F]">
        <div>Pre-order Savings</div>
        <div>-${selectedProduct.preorder_discount.toFixed(2)}</div>
      </div>

      <div className="pb-3 pt-[26px]">
        <div className="flex justify-between border-y border-[#C8C7C7] py-5 font-semibold lg:flex-row lg:justify-between lg:font-bold">
          <div>Item Total: </div>
          <div>${itemTotal}</div>
        </div>
      </div>
    </div>
  );
}
